import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

// Services
import AuthService from "../../services/auth.service";
import TokenService from "../../services/token.service";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();

    const [loadingAuthStatus, setLoadingAuthStatus] = useState(true);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [user, setUser] = useState({});

    useEffect(() => {
        // Is Signed In (TODO revise this)
        if (TokenService.getUser()) {
            getUser();
        }
    }, [isSignedIn]);

    const getUser = async () => {
        const { status, data } = await AuthService.getCurrentUser();

        if (status) {
            setUser({
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                role: data.role,
                plan_id: data.plan_id,
                plan_status: data.plan_status,
            });
            setIsSignedIn(true);
        } else {
            setLoadingAuthStatus(false);
        }
    };

    const signIn = async (values, callback) => {
        const { status, data, code, message } = await AuthService.signIn(values);

        if (status) {
            setUser({
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                role: data.role,
                plan_id: data.plan_id,
                plan_status: data.plan_status,
            });
            setIsSignedIn(true);
            callback(false, code, data);
        } else {
            callback(true, code);
        }
    };

    const isUserSubscribed = () => {
        return user.plan_id && user.plan_status !== "cancelled";
    };

    const signOut = () => {
        AuthService.signOut();
        setIsSignedIn(false);
        navigate("/account/sign-in", { replace: true });
    };

    let value = {
        isSignedIn,
        loadingAuthStatus,
        signIn,
        signOut,
        getUser,
        user,
        isUserSubscribed,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
