import axios from 'axios'

// Services
import TokenService from "./token.service"


const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    timeout: 40000,
    timeoutErrorMessage: "timeout",
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8'
    },
});

client.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken();
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

client.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        if (originalConfig.url !== "/accounts/token/" && originalConfig.url !== "/accounts/token/refresh/" && err.response) {
            // Access Token was expired
            if ((err.response.status === 403 || err.response.status === 401) && !originalConfig._retry) {
                originalConfig._retry = true;

                try {
                    const rs = await client.post("/accounts/token/refresh/", {
                        refresh: TokenService.getLocalRefreshToken(),
                    });
                    const { access } = rs.data;
                    TokenService.updateLocalAccessToken(access);
                    return client(originalConfig);
                } catch (_error) {
                    if (_error.response && _error.response.status === 401) {
                        TokenService.removeUser()
                        window.location.replace('/account/sign-in')
                    }
                    return Promise.reject(_error);
                }
            }
        }
        return Promise.reject(err);
    }
);

export default client;