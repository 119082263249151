import React, { useEffect, useContext, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

// Context
import { AuthContext } from "../context/AuthContext";
import theme from "../theme/ThemeContext";

// Utils
import { Colors } from "../utils/constants";

// MUI
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tooltip from "@mui/material/Tooltip";

// Components
import NavLink from "../components/NavLink";

// Images
import ArrowDown from "../../images/icons/arrow_down.svg";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";

const DashboardLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const { user, isUserSubscribed, loadingAuthStatus, isSignedIn } = useContext(AuthContext);

    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        if (!loadingAuthStatus && !isSignedIn) {
            navigate("/account/sign-in");
        }
    }, [loadingAuthStatus, isSignedIn, navigate]);

    useEffect(() => {
        setMenuOpen(!menuOpen);
    }, [location]);

    return (
        <Grid container spacing={6}>
            <Grid item xs={12} md={4} lg={3}>
                <Box
                    component="div"
                    sx={{
                        display: "grid",
                        gridTemplateRows: {
                            xs: "24px auto 24px 1fr 28px",
                            md: "32px auto 24px 1fr 48px",
                        },
                        gridTemplateColumns: "16px 32px 1fr",
                    }}
                >
                    {isUserSubscribed() && (
                        <Tooltip placement="right" title="Premium">
                            <WorkspacePremiumIcon
                                sx={{
                                    gridRow: "1 / 3",
                                    gridColumn: "2 / 10",

                                    fontSize: "45px",
                                    color: Colors.primaryYellow,
                                    p: 1,
                                    zIndex: 2,
                                }}
                            />
                        </Tooltip>
                    )}
                    <Typography
                        variant="h2"
                        sx={{
                            gridRow: "2 / 3",
                            gridColumn: "3 / 10",
                            color: Colors.primary,
                        }}
                    >
                        {user.role === "designer" ? "Designer" : "Home Owner"}
                        <br></br> Dashboard
                    </Typography>

                    {isMobile && (
                        <IconButton
                            aria-label="open menu"
                            onClick={() => setMenuOpen(!menuOpen)}
                            sx={{
                                gridRow: "2 / 3",
                                gridColumn: "1 / 10",

                                alignSelf: "center",
                                justifySelf: "flex-end",
                                mr: 3,
                            }}
                        >
                            <Box
                                component="img"
                                src={ArrowDown}
                                alt="arrow down"
                                sx={{
                                    width: "20px",
                                    transition: "0.3s ease",
                                    transform:
                                        isMobile && menuOpen ? "rotate(0deg)" : "rotate(-180deg)",
                                }}
                            />
                        </IconButton>
                    )}

                    <Box
                        sx={{
                            border: `4px solid ${Colors.primaryYellow}`,
                            gridRow: "1 / 6",
                            gridColumn: "2 / 4",
                        }}
                    />

                    <Stack
                        spacing={3}
                        sx={{
                            gridRow: "4 / 5",
                            gridColumn: "3 / 6",
                            overflow: "hidden",
                            transition: "0.5s ease",
                            maxHeight: isMobile && menuOpen ? "0px" : "600px",
                        }}
                    >
                        <NavLink to="collection">
                            <Typography variant="h3" sx={{ color: Colors.background }}>
                                Collection
                            </Typography>
                        </NavLink>
                        <NavLink to="mood-boards">
                            <Typography variant="h3" sx={{ color: Colors.background }}>
                                Mood Boards
                            </Typography>
                        </NavLink>
                        {user.role === "designer" && (
                            <NavLink to="public-profile">
                                <Typography variant="h3" sx={{ color: Colors.background }}>
                                    Public Profile
                                </Typography>
                            </NavLink>
                        )}
                        <NavLink to="account">
                            <Typography variant="h3" sx={{ color: Colors.background }}>
                                Account
                            </Typography>
                        </NavLink>
                        {user.role === "designer" && (
                            <NavLink to="billing">
                                <Typography variant="h3" sx={{ color: Colors.background }}>
                                    Billing & Plans
                                </Typography>
                            </NavLink>
                        )}
                    </Stack>
                </Box>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
                <Outlet />
            </Grid>
        </Grid>
    );
};

export default DashboardLayout;
