import React from 'react'
import { Outlet } from "react-router-dom";

// Utils
import { PageGutter, NavHeight } from '../utils/constants'

// MUI
import Box from '@mui/material/Box'

// Components
import Footer from './components/Footer'
import Navbar from './components/Navbar'


const PublicLayout = () => {
    return (
        <>
            <Navbar />
            <Box
                sx={{
                    position: "relative",
                    minHeight: `calc(100vh - (${NavHeight}))`,
                    padding: { xs: `24px ${PageGutter}`, sm: `24px ${PageGutter} 48px` },
                    overflowX: "hidden"
                }}
            >
                <Outlet />
            </Box>
            <Footer />
        </>
    )
}

export default PublicLayout
