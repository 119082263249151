// MUI
import { createTheme } from "@mui/material/styles";

// Utils
import { Colors } from '../utils/constants'

// Fonts
import ChaletWoff from '../fonts/Chalet-NewYorkSixty.woff'
import ChaletWoff2 from '../fonts/Chalet-NewYorkSixty.woff2'

const HeadingFontFamily = 'Chalet, sans-serif'
const BodyFontFamily = 'Work Sans'

const theme = createTheme({
    typography: {
        fontFamily: HeadingFontFamily,
        color: Colors.body
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "@font-face": {
                    fontFamily: HeadingFontFamily,
                    src: `url(${ChaletWoff}) format("woff"), url(${ChaletWoff2}) format("woff2")`,
                    fontWeight: "700",
                    fontStyle: "normal",
                    fontStretch: "normal",
                    fontDisplay: "swap"
                }
            }
        },
    },
    shape: {
        borderRadius: 7,
    },
    palette: {
        primary: {
            main: Colors.primary,
            light: Colors.white,
        },
        neutral: {
            main: Colors.test,
            light: Colors.test,
        },
        success: {
            main: Colors.success,
        },
        error: {
            main: Colors.error,
        },
    },
    shadows: {
        0: "",
        1: `${Colors.shadow}`,
        4: `${Colors.shadow}`,
        6: `${Colors.shadowBig}`,
        8: `${Colors.shadowBig}`,
        16: `${Colors.shadowBig}`,
        24: `${Colors.shadowBig}`,
    }
});

const fontSize = 14;
const htmlFontSize = 16;
const coef = fontSize / 14;

theme.typography = {
    pxToRem: (size) => `${(size / htmlFontSize) * coef}rem`,
    h1: {
        fontWeight: 700,
        fontSize: "62px",
        lineHeight: "70px",
        color: Colors.textPrimary,
        fontFamily: HeadingFontFamily,

        [theme.breakpoints.up("md")]: {
            fontSize: "92px",
            lineHeight: "100px"
        }
    },
    h2: {
        fontWeight: 700,
        fontSize: "28px",
        lineHeight: "45px",
        color: Colors.textPrimary,
        fontFamily: HeadingFontFamily,

        [theme.breakpoints.up("md")]: {
            fontSize: "48px",
            lineHeight: "60px"
        }
    },
    h3: {
        fontWeight: 700,
        fontSize: "28px",
        lineHeight: "38px",
        color: Colors.textPrimary,
        fontFamily: HeadingFontFamily,

        [theme.breakpoints.up("sm")]: {
            fontSize: "32px",
            lineHeight: "38px"
        }
    },
    h4: {
        fontWeight: 700,
        fontSize: "17px",
        lineHeight: '24px',
        color: Colors.body,
        fontFamily: HeadingFontFamily
    },
    h5: {
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: Colors.textPrimary,
        fontFamily: BodyFontFamily
    },
    h6: {
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "22px",
        color: Colors.textPrimary,
        fontFamily: BodyFontFamily,
    },
    paragraph: {
        fontWeight: 300,
        fontSize: "14px",
        lineHeight: "22px",
        color: Colors.greyPrimary,
        fontFamily: BodyFontFamily
    },
    link: {
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "22px",
        color: Colors.textPrimary,
        fontFamily: BodyFontFamily
    },
};

theme.components = {
    MuiTypography: {
        defaultProps: {
            variantMapping: {
                paragraph: "p", // This was done because the 'paragraph' variant was mapping to <span>
            },
        },
        styleOverrides: {
            root: ({ ownerState }) => ({
                ...(ownerState.variant === "h4" && {
                    '& span': {
                        fontWeight: 400,
                    }
                })
            })
        }
    },
    MuiButton: {
        styleOverrides: {
            root: ({ ownerState }) => ({
                '&.MuiButtonBase-root': {
                    fontSize: "14px" // For href buttons
                },
                fontFamily: BodyFontFamily,
                fontWeight: "700",
                borderRadius: "0",
                width: "fit-content",
                ...(ownerState.variant === "contained" && {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.light,
                }),
                ...(ownerState.variant === "plain" && {
                    border: 'none',
                    color: Colors.test,
                }),
                ...(ownerState.disabled && {
                    cursor: 'not-allowed !important',
                    pointerEvents: 'all !important',
                    backgroundColor: `${Colors.grey} !important`,
                    color: `${theme.palette.primary.light} !important`,
                    border: `none !important`,
                }),
            }),
        },
        variants: [
            {
                props: { variant: "plain" },
                style: {
                    backgroundColor: "transparent",
                    color: theme.palette.primary.main,
                },
            },
            {
                props: { variant: "success" },
                style: {
                    backgroundColor: Colors.success,
                    borderColor: Colors.success,
                    color: Colors.white,
                    "&:hover": {
                        backgroundColor: Colors.success,
                        opacity: 0.9
                    },
                },
            },
        ],
    },
    MuiIconButton: {
        styleOverrides: {
            root: ({ ownerState }) => ({
                width: "37px",
                height: "37px",
                //padding: "10px",
                // filter: "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.25))",
                'svg': {
                    height: "17px",
                    width: "17px"
                },
                ...(ownerState.disabled && {
                    cursor: 'not-allowed !important',
                    opacity: "0.3",
                })
            })
        }
    },
    MuiLink: {
        styleOverrides: {
            root: {
                // fontFamily: HeadingFontFamily,
                // fontSize: "14px",
                // fontWeight: 700,
                // color: Colors.primary,
                // textDecoration: 'none',
                cursor: "pointer"
            },
        },
    },
    MuiTextField: {
        styleOverrides: {
            root: {
                fontFamily: BodyFontFamily,
                fontSize: "14px",
                'fieldset': {
                    borderColor: Colors.primary,
                    borderRadius: "0 !important",
                    borderWidth: "3px"
                }
            },
        },
    },
    MuiAutocomplete: {
        styleOverrides: {
            root: {
                '.MuiInputBase-root': {
                    minHeight: "37px !important",
                    padding: "5px 65px 4px 4px !important"
                },
                '.MuiAutocomplete-endAdornment': {
                    top: "calc(50% - 19px)"
                },
                'li': {
                    background: "red"
                }
            },
        },
    },
    MuiPopper: {
        styleOverrides: {
            root: {
                fontFamily: BodyFontFamily,
                fontSize: "14px",
            }
        },
    },
    MuiFormControlLabel: {
        styleOverrides: {
            root: {
                fontFamily: HeadingFontFamily,
                fontSize: "12px",
                color: Colors.greyPrimary
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                fontFamily: BodyFontFamily,
                fontSize: "14px",
                color: Colors.greyPrimary
            },
        },
    },
    MuiToggleButtonGroup: {
        styleOverrides: {
            root: {
                border: `3px solid ${Colors.primary}`,
                borderRadius: 0
            },
        },
    },
    MuiToggleButton: {
        styleOverrides: {
            root: {
                fontFamily: BodyFontFamily,
                padding: "10px 25px",
                borderColor: "transparent",
                fontWeight: 700,
                color: Colors.primary,
                transition: "all 0.3s ease",

                "&.MuiToggleButtonGroup-grouped": {
                    borderRadius: "0 !important",
                },
                "&.Mui-selected": {
                    backgroundColor: Colors.primary,
                    color: Colors.white,
                    "&:hover": {
                        backgroundColor: Colors.primary,
                    },
                },
                "&:hover": {
                    backgroundColor: Colors.accent,
                    color: Colors.white,
                },
            },
        },
    },
    MuiAlert: {
        styleOverrides: {
            root: {
                fontFamily: BodyFontFamily,
                fontSize: "14px"
            }
        }
    },
    MuiSkeleton: {
        styleOverrides: {
            root: {
                backgroundColor: Colors.background
            }
        }
    },
};

export default theme;