import React, { useEffect } from 'react'
import {
    Outlet,
    useNavigate
} from 'react-router-dom';

// Utils
import { NavHeight } from '../utils/constants'

// Context
import { useAuth } from '../../common/context/AuthContext'

// MUI
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

// Images
import Circle from '../../images/background_circle.svg'
import Diamond from '../../images/background_diamond.svg'


const AuthLayout = () => {
    const navigate = useNavigate()
    let { isSignedIn } = useAuth()

    useEffect(() => {
        if (isSignedIn) {
            navigate('/dashboard/collection/', { replace: true })
        }
    }, [isSignedIn, navigate])

    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
                minHeight: `calc(100vh - (${(NavHeight)} + 80px))`
            }}
        >
            <Box
                component="img"
                src={Circle}
                alt="circle"
                sx={{
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    zIndex: '-1'
                }}
            />
            <Outlet />
            <Box
                component="img"
                src={Diamond}
                alt="diamond"
                sx={{
                    position: 'absolute',
                    top: '90px',
                    right: '0',
                    zIndex: '-1'
                }}
            />
        </Stack>
    )
}

export default AuthLayout