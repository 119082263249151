import React, { useEffect, Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

// GA
import ReactGA from "react-ga4";

// Utils
import PrivateRoute from "./common/components/PrivateRoute";
//import ScrollToTop from './common/components/ScrollToTop'
import Loader from "./common/components/Loader";

// Context
import theme from "./common/theme/ThemeContext";
import { ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "./common/context/AuthContext";
import { CollectionContextProvider } from "./common/context/CollectionContext";

// MUI
import CssBaseline from "@mui/material/CssBaseline";

// Layout
import PublicLayout from "./common/layout/PublicLayout";
import AuthLayout from "./common/layout/AuthLayout";
import DashboardLayout from "./common/layout/DashboardLayout";

// STATIC
const AsyncHome = lazy(() => import("./pages/static/landing"));
const AsyncContactUs = lazy(() => import("./pages/static/contactUs"));
const AsyncPricing = lazy(() => import("./pages/static/pricing"));
const AsyncTerms = lazy(() => import("./pages/static/termsConditions"));
const AsyncPrivacy = lazy(() => import("./pages/static/privacyPolicy"));

const AsyncSubscribe = lazy(() => import("./pages/static/subscribe"));

// AUTH
const AsyncSignIn = lazy(() => import("./pages/auth/SignIn"));
const AsyncRegisterRole = lazy(() => import("./pages/auth/RegisterRole"));
const AsyncRegisterForm = lazy(() => import("./pages/auth/RegisterForm"));
const AsyncForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));
const AsyncResetPassword = lazy(() => import("./pages/auth/ResetPassword"));

// PUBLIC
const AsyncMoodBoards = lazy(() => import("./pages/shop/moodBoards"));
const AsyncMoodBoardDetail = lazy(() => import("./pages/shop/moodBoardDetail"));
const AsyncDesigners = lazy(() => import("./pages/shop/designers"));
const AsyncDesignerDetail = lazy(() => import("./pages/shop/designerDetail"));
const AsyncProducts = lazy(() => import("./pages/shop/products"));
const AsyncProductDetail = lazy(() => import("./pages/shop/productDetail"));
const AsyncSuppliers = lazy(() => import("./pages/shop/suppliers"));

const AsyncMoodBoardCreator = lazy(() => import("./pages/dashboard/moodBoardCreator"));

// DASHBOARD
const AsyncCollection = lazy(() => import("./pages/dashboard/collection"));
const AsyncDashboardMoodBoards = lazy(() => import("./pages/dashboard/moodBoards"));
const AsyncPublicProfile = lazy(() => import("./pages/dashboard/publicProfile"));
const AsyncAccount = lazy(() => import("./pages/dashboard/account"));
const AsyncBilling = lazy(() => import("./pages/dashboard/billing"));

// const AsyncSuppliers = lazy(() => import("./pages/shop/Suppliers"));

const AsyncNotFound = lazy(() => import("./common/layout/NotFound"));

const App = () => {
    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA_TAG, {
            testMode: process.env.REACT_APP_GA_TEST_MODE === "true",
        });
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <HelmetProvider>
                    <AuthProvider>
                        <CollectionContextProvider>
                            <Suspense fallback={<Loader fullscreen />}>
                                <Routes>
                                    <Route path="/" element={<PublicLayout />}>
                                        {/* STATIC */}
                                        <Route
                                            index
                                            element={
                                                <Suspense fallback={<Loader />}>
                                                    <AsyncHome />
                                                </Suspense>
                                            }
                                        />
                                        <Route
                                            path="pricing"
                                            element={
                                                <Suspense fallback={<Loader />}>
                                                    <AsyncPricing />
                                                </Suspense>
                                            }
                                        />
                                        <Route
                                            path="contact-us"
                                            element={
                                                <Suspense fallback={<Loader />}>
                                                    <AsyncContactUs />
                                                </Suspense>
                                            }
                                        />
                                        <Route
                                            path="terms-and-conditions"
                                            element={
                                                <Suspense fallback={<Loader />}>
                                                    <AsyncTerms />
                                                </Suspense>
                                            }
                                        />
                                        <Route
                                            path="privacy-policy"
                                            element={
                                                <Suspense fallback={<Loader />}>
                                                    <AsyncPrivacy />
                                                </Suspense>
                                            }
                                        />

                                        {/* AUTH */}
                                        <Route path="account" element={<AuthLayout />}>
                                            <Route
                                                index
                                                path="sign-in"
                                                element={
                                                    <Suspense fallback={<Loader />}>
                                                        <AsyncSignIn />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="sign-in/:activationKey"
                                                element={
                                                    <Suspense fallback={<Loader />}>
                                                        <AsyncSignIn />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="register"
                                                element={
                                                    <Suspense fallback={<Loader />}>
                                                        <AsyncRegisterRole />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="register/:userType"
                                                element={
                                                    <Suspense fallback={<Loader />}>
                                                        <AsyncRegisterForm />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="forgot-password"
                                                element={
                                                    <Suspense fallback={<Loader />}>
                                                        <AsyncForgotPassword />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="reset-password/:resetKey"
                                                element={
                                                    <Suspense fallback={<Loader />}>
                                                        <AsyncResetPassword />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>

                                        {/* PUBLIC */}
                                        <Route path="mood-boards">
                                            <Route
                                                path=""
                                                element={
                                                    <Suspense fallback={<Loader />}>
                                                        <AsyncMoodBoards />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="detail/:slug"
                                                element={
                                                    <Suspense fallback={<Loader />}>
                                                        <AsyncMoodBoardDetail />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                        <Route path="designers">
                                            <Route
                                                path=""
                                                element={
                                                    <Suspense fallback={<Loader />}>
                                                        <AsyncDesigners />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="detail/:pk"
                                                element={
                                                    <Suspense fallback={<Loader />}>
                                                        <AsyncDesignerDetail />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                        <Route path="products">
                                            <Route
                                                path=""
                                                element={
                                                    <Suspense fallback={<Loader />}>
                                                        <AsyncProducts />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="detail/:slug"
                                                element={
                                                    <Suspense fallback={<Loader />}>
                                                        <AsyncProductDetail />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                        <Route path="partners">
                                            <Route
                                                path=""
                                                element={
                                                    <Suspense fallback={<Loader />}>
                                                        <AsyncSuppliers />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>

                                        {/* DASHBOARD */}
                                        <Route path="dashboard" element={<DashboardLayout />}>
                                            <Route
                                                path="collection"
                                                element={
                                                    <Suspense fallback={<Loader />}>
                                                        <AsyncCollection />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="mood-boards"
                                                element={
                                                    <Suspense fallback={<Loader />}>
                                                        <AsyncDashboardMoodBoards />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="public-profile"
                                                element={
                                                    <Suspense fallback={<Loader />}>
                                                        <AsyncPublicProfile />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="account"
                                                element={
                                                    <Suspense fallback={<Loader />}>
                                                        <AsyncAccount />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="billing"
                                                element={
                                                    <Suspense fallback={<Loader />}>
                                                        <AsyncBilling />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                    </Route>

                                    {/* CREATOR */}
                                    <Route
                                        path="mood-board-creator/:slug"
                                        element={
                                            <Suspense fallback={<Loader />}>
                                                <AsyncMoodBoardCreator />
                                            </Suspense>
                                        }
                                    />

                                    {/* 404 */}
                                    <Route path="*" element={<AsyncNotFound />} />
                                </Routes>
                            </Suspense>
                        </CollectionContextProvider>
                    </AuthProvider>
                </HelmetProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;
