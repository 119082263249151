import React from 'react'

// Utils
import { Colors } from '../../common/utils/constants'

// Components


// MUI
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import MuiIconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography'

// Icons
import CloseIcon from '@mui/icons-material/CloseOutlined';


const DialogComp = ({ open = false, handleClose, title, children, fullWidth = true, maxWidth = "sm", sx, ...rest }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            scroll="body"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={sx}
            {...rest}
        >
            <DialogContent>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={1.5}
                >
                    <Typography variant="h4">{title}</Typography>
                    <MuiIconButton onClick={handleClose}>
                        <CloseIcon sx={{ color: Colors.primary }} />
                    </MuiIconButton>
                </Stack>
                {children}
            </DialogContent>
        </Dialog>
    )
}

export default DialogComp
