import React, { useState } from "react";
import styled from "@emotion/styled";

// Context
import { useAuth } from "../../context/AuthContext";

// Utils
import { PageGutter, Colors } from "../../../common/utils/constants";

// MUI
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";

// Components
import NavLink from "../../components/NavLink";
import Button from "../../components/Button";

// Images
// import Logo from '../../../images/logo.svg'
// import LogoIcon from '../../../images/logo_square_corners.svg'

import Logo from "../../../images/primary_logo_black.png";
import LogoIcon from "../../../images/secondary_logo_black.png";

import Menu from "../../../images/icons/menu.svg";
// import Close from '../../../images/icons/close.svg'

const MobileMenuContainer = styled.div`
  background: ${Colors.white};
  position: fixed;
  z-index: 999;
  top: -200vh;
  left: 0;
  height: 100vh;
  width: 100%;
  transition: 0.5s ease;

  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.active &&
    `
        top: 0;
    `}
`;

const Navbar = ({ creator }) => {
  const { isSignedIn, signOut, user } = useAuth();

  const [active, setActive] = useState(false);

  return (
    <nav>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          background: creator ? Colors.primaryYellow : "none",
          padding: {
            xs: `10px ${creator ? "15px" : PageGutter}`,
            sm: `16px ${creator ? "15px" : PageGutter}`,
          },
        }}
      >
        {/* Desktop Menu */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
          sx={{
            width: "100%",
          }}
        >
          <Stack direction="row" alignItems="center" spacing={3}>
            <Link href="/">
              <img src={Logo} alt="design central logo" style={{ width: "100px" }} />
            </Link>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{
                display: { xs: "none", lg: "flex" },
              }}
            >
              <NavLink to="/mood-boards/" nounderline>
                Mood Boards
              </NavLink>
              <NavLink to="/designers/" nounderline>
                Designers
              </NavLink>
              <NavLink to="/products/" nounderline>
                Products
              </NavLink>
              <NavLink to="/partners/" nounderline>
                Partners
              </NavLink>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              display: { xs: "none", lg: "flex" },
            }}
          >
            {user.role !== "home_owner" && (
              <NavLink to="/pricing/" nounderline>
                Pricing
              </NavLink>
            )}
            <NavLink to="/contact-us/" nounderline>
              Contact
            </NavLink>
            {isSignedIn ? (
              <>
                <NavLink to="/dashboard/collection" nounderline bold>
                  My Dashboard
                </NavLink>
                <Button onClick={signOut} showarrow={false}>
                  Sign Out &nbsp;
                  {user.email && (
                    <>
                      ({user.first_name.substring(0, 1)}
                      {user.last_name.substring(0, 1)})
                    </>
                  )}
                </Button>
              </>
            ) : (
              <>
                <NavLink to="/account/sign-in/" nounderline bold>
                  Sign In
                </NavLink>
                <Button href="/account/register/" showarrow={false}>
                  Create an Account
                </Button>
              </>
            )}
          </Stack>
        </Stack>

        {/* Mobile Menu */}

        <IconButton
          aria-label="open menu"
          sx={{
            display: { xs: "flex", lg: "none" },
            pl: 2,
          }}
          onClick={() => setActive(!active)}
        >
          <img src={Menu} alt="menu" />
        </IconButton>

        <MobileMenuContainer
          sx={{ display: { xs: "block", md: "none" }, overflow: "hidden" }}
          active={active}
          onClick={() => setActive(!active)}
        >
          <Stack spacing={4} alignItems="center">
            <Box component="img" alt="DC logo" src={LogoIcon} mb={4} sx={{ width: "40px" }} />
            <NavLink mobile to="/mood-boards/" nounderline>
              Mood Boards
            </NavLink>
            <NavLink mobile to="/designers/" nounderline>
              Designers
            </NavLink>
            <NavLink mobile to="/products/" nounderline>
              Products
            </NavLink>
            <NavLink mobile to="/partners/" nounderline>
              Partners
            </NavLink>

            <Divider sx={{ width: "200px", margin: "10px 0 !important" }} />

            {user.role !== "home_owner" && (
              <NavLink mobile to="/pricing/" nounderline>
                Pricing
              </NavLink>
            )}
            <NavLink mobile to="/contact-us/" nounderline>
              Contact
            </NavLink>
            {isSignedIn ? (
              <>
                <NavLink to="/dashboard/collection" nounderline bold>
                  My Dashboard
                </NavLink>
                <Button onClick={signOut} showarrow={false}>
                  Sign Out &nbsp;
                  {user.email && (
                    <>
                      ({user.first_name.substring(0, 1)}
                      {user.last_name.substring(0, 1)})
                    </>
                  )}
                </Button>
              </>
            ) : (
              <>
                <NavLink to="/account/sign-in/" nounderline bold>
                  Sign In
                </NavLink>
                <Button href="/account/register/" showarrow={false}>
                  Create an Account
                </Button>
              </>
            )}
          </Stack>
        </MobileMenuContainer>
      </Stack>
    </nav>
  );
};

export default Navbar;
