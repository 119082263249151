import React from 'react'
import { NotificationMessages } from './constants'
import toaster from 'toasted-notes'
import ToastNotification from '../components/ToastNotification'

export const handleError = (error) => {
    if (error && error.response) {
        if (error.response.status === 400) {
            const errorData = getErrorData(error)
            if (errorData) {
                return errorData
            }

            return {
                status: false,
                code: error.response.status,
                message: "Bad request"
            }
        }

        if (error.response.status === 401) {
            const errorData = getErrorData(error)
            if (errorData) {
                return errorData
            }

            return {
                status: false,
                code: error.response.status,
                message: "Forbidden"
            }
        }

        if (error.response.status === 403) {
            const errorData = getErrorData(error)
            if (errorData) {
                return errorData
            }

            return {
                status: false,
                code: error.response.status,
                message: "Unauthorized"
            }
        }

        if (error.response.status === 404) {
            return {
                status: false,
                code: error.response.status,
                message: "Not found"
            }
        }

        if (error.response.status === 409) {
            return {
                status: false,
                code: error.response.status,
                message: "Conflict"
            }
        }

        if (error.response.status === 413) {
            return {
                status: false,
                code: error.response.status,
                message: "Selected profile picture is too large"
            }
        }
    }

    return {
        status: false,
        code: null,
        message: NotificationMessages.genericErrorMessage
    }
}

const getErrorData = (error) => {
    if (error.response.data['non_field_errors']) {
        return {
            status: false,
            code: error.response.status,
            message: error.response.data['non_field_errors'][0]
        }
    }

    if (error.response.data && error.response.data !== "") {
        return {
            status: false,
            code: error.response.status,
            message: error.response.data
        }
    }

    return null
}

export const constructQueryString = (values) => {
    let queryString = ''
    Object.entries(values).map(([k, v]) => {
        if ((Array.isArray(v) && v.length > 0) || (!Array.isArray(v) && v !== "")) {
            if (k !== 'page') {
                if (Array.isArray(v)) {
                    queryString += `&${k}=[${v}]`
                } else {
                    queryString += `&${k}=${v}`
                }
            }
        }
        return queryString
    });
    return queryString
}

export const Notify = (message, type, duration = 5000, position = 'bottom-right') => {
    toaster.notify(({ onClose }) => (
        <ToastNotification type={type} message={message} onClose={onClose} />
    ), {
        position: position,
        duration: duration,
    });
}

export const FormatPrice = (price) => {
    return parseFloat(price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

export const truncate = (str, num) => {
    if (str && str.length > num) {
        let subStr = str.substring(0, num);
        return subStr + "...";
    } else {
        return str;
    }
}