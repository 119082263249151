import React from 'react'
import styled from '@emotion/styled'
import { useMatch, useResolvedPath } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'


// Utils
import { Colors } from '../utils/constants'

const StyledHashLink = styled(HashLink)`
    position: relative;
    font-family: 'Work Sans', sans-serif;
    font-size: 15px;
    font-weight: 300;
    transition: 0.3s ease;
    text-decoration: none;
    color: ${Colors.textPrimary};

    &:hover {
        color: ${Colors.grey} !important;

        h3{
            transition: 0.3s ease;
            color: ${Colors.grey} !important;
        }
    } 

    ${props => props.plain ? `padding 0;` : `padding: 16px;`};
    ${props => props.bold ? `font-weight: 700` : ``};
    ${props => props.active === "true" && `
        &:after {
            z-index: -1;
            position: absolute;
            content: '';
            background: ${Colors.primaryYellow};
            height: 100px;
            width: 100%;
            left: 0;
            bottom: -10px;
        }
        h3{
            color: black !important;
        }
    `}

    ${props => props.mobile === "true" && `
        margin: 0 !important;

        &:after {
            height: 40px;
            bottom: 0px;
        }
    `}
`

const NavLink = ({
    bold,
    plain = false,
    mobile = false,
    to,
    children
}) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: false });

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <StyledHashLink
            bold={bold || match ? "true" : ""}
            plain={plain ? "true" : ""}
            mobile={mobile ? "true" : ""}
            to={to}
            active={match ? "true" : ""}
            onClick={scrollToTop}
        >
            {children}
        </StyledHashLink>
    )
}

export default NavLink