import React from "react";

// Context
import { useAuth } from "../../context/AuthContext";

// Utils
import { Colors, PageGutter } from "../../utils/constants";

// MUI
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";

// Components
import NavLink from "../../../common/components/NavLink";

// Images
import LogoSquare from "../../../images/logo_square.svg";
import CornerTR from "../../../images/icons/corner_w_tr.svg";

import FB from "../../../images/icons/fb_d.svg";
import IG from "../../../images/icons/in_d.svg";
// import Pin from "../../../images/icons/pin.svg"
// import Lin from "../../../images/icons/lin.svg"

const Footer = () => {
  const { isSignedIn } = useAuth();

  return (
    <footer id="footer">
      <Stack
        spacing={4}
        sx={{
          background: Colors.background,
          padding: { xs: `32px ${PageGutter}`, sm: `32px ${PageGutter}` },
        }}
      >
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
          <img src={LogoSquare} alt="design central logo" style={{ width: "40px" }} />
          <img src={CornerTR} alt="corner" />
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "center", sm: "flex-start" }}
          justifyContent="space-between"
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="flex-start"
            justifyContent="space-between"
            spacing={4}
            px={3}
          >
            <Stack
              direction="column"
              alignItems={{ xs: "center", sm: "flex-start" }}
              justifyContent="space-between"
              spacing={1}
            >
              <Typography variant="h4" mb={1}>
                Quick Links
              </Typography>
              {isSignedIn ? (
                <NavLink plain to="/dashboard/collection">
                  My Dashboard
                </NavLink>
              ) : (
                <>
                  <NavLink plain to="/account/sign-in/">
                    Sign In
                  </NavLink>
                  <NavLink plain to="/account/register/">
                    Create an Account
                  </NavLink>
                </>
              )}
              {isSignedIn ? (
                <NavLink plain to="/dashboard/mood-boards">
                  Create Mood Board
                </NavLink>
              ) : (
                <NavLink plain to="/mood-board-creator/demo">
                  Create Mood Board
                </NavLink>
              )}
            </Stack>
            <Stack
              direction="column"
              alignItems={{ xs: "center", sm: "flex-start" }}
              justifyContent="space-between"
              spacing={1}
              sx={{
                display: { xs: "none", md: "flex" },
              }}
            >
              <Typography variant="h4" mb={1}>
                Our Offering
              </Typography>
              <NavLink plain to="/mood-boards/">
                Mood Boards
              </NavLink>
              <NavLink plain to="/designers/">
                Designers
              </NavLink>
              <NavLink plain to="/products/">
                Products
              </NavLink>
              {/* <NavLink plain to="/partners/">Partners</NavLink> */}
            </Stack>
            <Stack
              direction="column"
              alignItems={{ xs: "center", sm: "flex-start" }}
              justifyContent="space-between"
              spacing={1}
            >
              <Typography variant="h4" mb={1}>
                Legal
              </Typography>
              <NavLink plain to="/terms-and-conditions/">
                Terms &amp; Conditions
              </NavLink>
              <NavLink plain to="/privacy-policy/">
                Privacy Policy
              </NavLink>
            </Stack>
          </Stack>
          <Stack
            direction="column"
            alignItems={{ xs: "center", sm: "flex-end" }}
            justifyContent="space-between"
            spacing={2}
            px={3}
          >
            <Typography variant="h4" mb={1} mt={{ xs: 3, sm: 0 }}>
              Contact Us
            </Typography>
            <Link href="mailto:info@designcentral.co.za">
              <Typography variant="paragraph">info@designcentral.co.za</Typography>
            </Link>

            <Stack direction="row" spacing={2}>
              <Link
                href="https://www.instagram.com/designcentral.co.za/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IG} alt="instagram" />
              </Link>
              <Link
                href="https://www.facebook.com/designcentral.co.za"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={FB} alt="facebook" />
              </Link>
            </Stack>
          </Stack>
        </Stack>
        <Divider sx={{ height: "4px", background: Colors.primary }} />
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "center", sm: "flex-start" }}
          justifyContent="space-between"
          spacing={1}
          px={3}
        >
          <Typography variant="paragraph" sx={{ color: Colors.textTertiary }}>
            © Copyright Design Central
          </Typography>
          <Link href="https://www.infinitereality.co.za" target="_blank" rel="noopener noreferrer">
            <Typography variant="paragraph" textAlign="right">
              Developed By Infinite Reality
            </Typography>
          </Link>
        </Stack>
      </Stack>
    </footer>
  );
};

export default Footer;
