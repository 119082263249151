import React from 'react'

// MUI
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

// Images
import ArrowRight from '../../images/icons/arrow_right.svg'

const ButtonComp = React.forwardRef(({
	type = "button",
	variant = "contained",
	showarrow = true,
	isLoading = false,
	disabled = false,
	fullWidth = false,
	onClick,
	children,
	sx,
	...rest
}, ref) => {
	return (
		<Button
			type={type}
			disabled={disabled || isLoading}
			onClick={onClick}
			fullWidth={fullWidth}
			endIcon={isLoading ? <CircularProgress size={14} sx={{ color: 'white', ml: 1 }} /> : showarrow && <img src={ArrowRight} alt="arrow" />}
			variant={variant}
			ref={ref}
			sx={{
				py: 1.5,
				px: 4,
				...sx
			}}
			{...rest}
		>
			{children}
		</Button>
	)
})

export default ButtonComp