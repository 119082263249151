import React, { Component } from 'react'
import styled from 'styled-components'
import { Colors } from '../utils/constants'

// Images
import LoaderD from '../../images/logo_d.svg'
import LoaderC from '../../images/logo_c.svg'

const LoaderContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1003;
    width: auto;

    ${props => props.fullscreen && `
        position: fixed;
        width: 100%;
        height: 100vh;
        background: ${Colors.white};        
    `}
`

const LoaderBox = styled.div`
    position: relative;
    margin: 0 12px;
    width: ${props => props.boxSize};
    height: ${props => props.boxSize};

    .d {
        position: absolute;
        height: auto;
        width: ${props => props.iconSize};

        animation-name: move;
        animation-duration: 2000ms;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;

        @keyframes move {
            0%   {
                top: 0px;
                right: calc(100% - (${props => props.iconSize}));
                bottom: calc(100% - (${props => props.iconSize}));
                left: 0px;
                }
            25%  {
                top: 0px;
                right: 0px;
                bottom: calc(100% - (${props => props.iconSize}));
                left: calc(100% - (${props => props.iconSize}));
                }
            50%  {
                top: calc(100% - (${props => props.iconSize}));
                right: 0px;
                bottom: 0px;
                left: calc(100% - (${props => props.iconSize}));
                }
            75%  {
                top: calc(100% - (${props => props.iconSize}));
                right: calc(100% - (${props => props.iconSize}));
                bottom: 0px;
                left: 0px;
                }
            100% {
                top: 0px;
                right: calc(100% - (${props => props.iconSize}));
                bottom: calc(100% - (${props => props.iconSize}));
                left: 0px;
                }
        }
    }

    .c {
        position: absolute;
        height: auto;
        width: ${props => props.iconSize};

        animation-name: move2;
        animation-duration: 2000ms;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;

        @keyframes move2 {
            0%  {
                top: calc(100% - (${props => props.iconSize}));
                right: 0px;
                bottom: 0px;
                left: calc(100% - (${props => props.iconSize}));
                }
            25%  {
                top: calc(100% - (${props => props.iconSize}));
                right: calc(100% - (${props => props.iconSize}));
                bottom: 0px;
                left: 0px;
                }
            50% {
                top: 0px;
                right: calc(100% - (${props => props.iconSize}));
                bottom: calc(100% - (${props => props.iconSize}));
                left: 0px;
                }
            75%   {
                top: 0px;
                right: 0px;
                bottom: calc(100% - (${props => props.iconSize}));
                left: calc(100% - (${props => props.iconSize}));
                }
            100%  {
                top: calc(100% - (${props => props.iconSize}));
                right: 0px;
                bottom: 0px;
                left: calc(100% - (${props => props.iconSize}));
                }
        }
    }
`

class Loader extends Component {
    render() {
        const { fullscreen } = this.props

        return (
            <LoaderContainer fullscreen={fullscreen}>
                <LoaderBox boxSize={fullscreen ? '80px' : '20px'} iconSize={fullscreen ? '32px' : '8px'}>
                    <img className="d" src={LoaderD} alt="loader d" />
                    <img className="c" src={LoaderC} alt="loader c" />
                </LoaderBox>
            </LoaderContainer>
        )
    }
}

export default Loader