import React from 'react'
import styled from 'styled-components'

// Utils
import { Colors } from '../utils/constants'

// Images
import Close from '../../images/icons/close.svg'

const ToastContainer = styled.div`
    display: flex;
    padding: 12px 16px;
    position: relative;
    color: white;
    /* min-width: 280px; */
    margin-bottom: 10px;
    margin-right: 10px;
    font-family: Chalet, sans-serif;

    ${props => props.type === 'success' ?
        `
            background-color: ${Colors.success};
        `
        : props.type === 'error' ?
            `
            background-color: ${Colors.error};
        `
            :
            `
            background-color: ${Colors.primary};
        `
    }

    img {
        margin-left: 10px;

        &:hover {
            cursor: pointer;
        }
    }
`

const ToastNotification = ({
    type,
    message,
    onClose
}) => (
    <ToastContainer type={type}>
        {message}
        <img src={Close} alt="close" onClick={onClose} />
    </ToastContainer>
)

export default ToastNotification
