import React, { createContext, useEffect, useState } from 'react'

// Libraries
import stringInject from 'stringinject'

// Lodash
import _cloneDeep from 'lodash/cloneDeep'
import _reject from 'lodash/reject'

// Context
import { useAuth } from '../context/AuthContext'

// Utils
import { Notify } from '../../common/utils/helpers'
import { NotificationMessages } from '../../common/utils/constants'

// Services
import DashboardService from '../../services/dashboard.service'

// MUI
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

// Components
import Dialog from '../../common/components/Dialog'
import Button from '../../common/components/Button'


export const CollectionContext = createContext()

export const CollectionContextProvider = ({ children }) => {
    const { isSignedIn } = useAuth()
    const [accountModalOpen, setAccountModalOpen] = useState(false)
    const [collection, setCollection] = useState([])

    useEffect(() => {
        if (isSignedIn) {
            getUserCollection()
        }
    }, [isSignedIn])

    const getUserCollection = async () => {
        const { status, data } = await DashboardService.getUserCollectionSlugs()

        if (status) {
            setCollection(data)
        } else {
            Notify(stringInject(NotificationMessages.getListError, ["collection"]), "error")
        }
    }

    const toggleCollectProduct = (slug) => {
        return new Promise(async (resolve, reject) => {
            if (isSignedIn) {
                const { status } = await DashboardService.toggleCollectProduct(slug)

                if (status) {
                    updateCollectionList(slug)
                    resolve('success')
                } else {
                    reject('error')
                }
            } else {
                setAccountModalOpen(true)
                reject('error')
            }
        })
    }

    const updateCollectionList = (slug) => {
        let collectionArrayClone = _cloneDeep(collection)
        const exists = collectionArrayClone.includes(slug)

        if (exists) {
            collectionArrayClone = _reject(collectionArrayClone, function (e) {
                return e === slug
            });
            Notify(NotificationMessages.shop.successRemoveCollectedProduct, "success")
        } else {
            Notify(NotificationMessages.shop.successCollectProduct, "success")
            collectionArrayClone.push(slug)
        }

        setCollection(collectionArrayClone)
    }

    return (
        <>
            <CollectionContext.Provider
                value={{
                    collection,
                    toggleCollectProduct
                }}
            >
                {children}
            </CollectionContext.Provider>
            <Dialog
                title="Design Central Collection"
                open={accountModalOpen}
                handleClose={() => setAccountModalOpen(false)}
            >
                <Typography variant="paragraph" mb={2}>
                    Welcome! In order to collect products you will need to create a Design Central account.
                </Typography>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={1}
                >
                    <Button
                        variant="plain"
                        showarrow={false}
                        onClick={() => setAccountModalOpen(false)}
                    >
                        Not Right Now
                    </Button>
                    <Button
                        href="/account/register"
                        onClick={() => setAccountModalOpen(false)}
                    >
                        Create Account
                    </Button>
                </Stack>
            </Dialog>
        </>
    )
}