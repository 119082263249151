export const Colors = {
    'primary': '#000000',
    'primaryYellow': '#E8BF57',
    'primaryGreen': '#265B49',
    'primaryBrown': '#8F6956',

    'textPrimary': '#000000',
    'white': '#FFFFFF',
    'grey': '#6F6F6F',
    'background': '#E3E0D7',

    'success': '#265B49',
    'error': '#E8872D',

    'shadow': '0 5px 10px rgba(0 0 0 / 0.1)',
    'shadowBig': '0 6px 30px rgba(0 0 0 / 0.2)',

    'test': '#00ff00'
}

export const AppName = "| Design Central"
export const PageGutter = '3%'
export const NavHeight = '81px'

export const AUTH_USER_TOKEN_KEY = "dc-token"
//export const AUTH_USER_KEY = "dc-user"

export const ValidationMessages = {
    required: "Required",
    invalidEmail: "Invalid email",
    invalidURL: "Invalid Link",
    passwordLength: "Minimum password length is 8 characters",
    passwordMissmatch: "Passwords do not match",
}

export const NotificationMessages = {
    genericError: "Something went wrong, please try again later.",
    unauthenticatedError: "You need to sign in to able to {0}!",
    getListError: "An error occurred fetching {0} list!",

    public: {
        contactSuccess: "Your message has been submitted, we will be in touch soon."
    },
    auth: {
        authFailed: "Invalid email or password. Please ensure that your account has been activated.",
        invalidKey: "Invalid key provided!",
        usedKey: "This key has been already used!",
        activationSuccess: "Your account has been successfully activated.",
        userExists: "A user with this email already exists.",
        userDoesNotExist: "A user with this email does not exist.",
        activationEmailSent: "An activation link has been sent to your email.",
        resetPasswordEmailSent: "A reset password link has been sent to your email.",
        passwordResetSuccess: "Your password has been reset. You can now sign in with your new password.",
    },
    dashboard: {
        profileError: "An error occurred trying to load your profile details.",
        profileUpdateSuccess: "Profile details updated.",
        profileUpdateError: "Unable to update profile details.",
        passwordUpdateSuccess: "Password updated.",
        passwordUpdateError: "Unable to update password, ensure your current password is correct.",
        fileSizeError: "File size cannot be largers than 3mb.",
        projectAddSuccess: "Project added successfully, and availiable publicly.",
        projectDeleteSuccess: "Project deleted successfully.",
        userImageUploadSuccess: "Image uploaded successfully.",
        imageDeleteSuccess: "Image deleted successfully.",

        errorRemoveCollectProduct: "An error occurred while trying to remove the product from your collection.",
        errorLoadingMoodboard: "An error occurred while trying to load your mood board!",
        errorCreateMoodboard: "An error occurred while trying to create your mood board!",
        successRemoveMoodboard: "Mood board removed.",
        errorRemoveMoodboard: "An error occurred while trying to remove your mood board!",
        successSaveMoodboard: "Mood board saved.",
        errorSaveMoodboard: "An error occurred while trying to save your mood board!"
    },
    shop: {
        successCollectProduct: "Product added to your collection.",
        successRemoveCollectedProduct: "Product removed from your collection.",
    },
    billing: {
        errorCancelSub: "Unable to cancel subscription, please try again later.",
        successCancelSub: "Subscription cancelled successfully.",
        errorUpdateCard: "Unable to update your card, please try again later.",
        successUpdateCard: "Card details updated.",
        planActive: "You are already on this plan!"
    }
}