import api from './api'

// Helpers
import { handleError } from '../common/utils/helpers'

// Services
import TokenService from "./token.service"

const isBrowser = typeof window !== `undefined`


const register = async (data) => {
    if (!isBrowser) return false

    if (data.email) {
        data['email'] = data.email.toLowerCase();
    }

    return await api.post('/accounts/register/', data)
        .then(function (response) {
            if (response.status === 201) {
                return {
                    status: true
                }
            }

        })
        .catch(function (error) {
            return handleError(error)
        })
}

const activate = async (activationKey) => {
    if (!isBrowser) return false

    return await api.put(`/accounts/activate/${activationKey}/`)
        .then(function (response) {
            if (response.status === 200) {
                return {
                    status: true
                }
            }
        })
        .catch(function (error) {
            return handleError(error)
        })
}

const signIn = async (data) => {
    if (!isBrowser) return false

    if (data.email) {
        data['email'] = data.email.toLowerCase();
    }

    return await api.post('/accounts/token/', data)
        .then(function (response) {
            if (response.data.access) {
                TokenService.setUser(response.data);
                return {
                    status: true,
                    data: response.data
                };
            }
        })
        .catch(function (error) {
            return handleError(error)
        })
}

const getCurrentUser = async () => {
    if (!isBrowser) return false

    return await api.get('/accounts/profile_details/')
        .then(function (response) {
            if (response.status === 200) {
                //TokenService.setUser(response.data)

                return {
                    status: true,
                    data: response.data
                }
            }

            return {
                status: false
            }

        })
        .catch(function (error) {
            return handleError(error)
        })
}

const forgotPassword = async (data) => {
    if (!isBrowser) return false

    return await api.post('/accounts/forgot_password/', data)
        .then(function (response) {
            if (response.status === 200) {
                return {
                    status: true
                }
            }
        })
        .catch(function (error) {
            return handleError(error)
        })
}

const resetPassword = async (data, activationKey) => {
    if (!isBrowser) return false

    return await api.put(`/accounts/reset_password/${activationKey}/`, data)
        .then(function (response) {
            if (response.status === 200) {
                return {
                    status: true
                }
            }
        })
        .catch(function (error) {
            return handleError(error)
        })
}

// ACCOUNT

const updateUserDetails = async (data) => {
    if (!isBrowser) return false

    return api.put('/accounts/update_profile/', data)
        .then(function (response) {
            if (response.status === 200) {
                let userData = response.data
                return {
                    status: true,
                    user: userData
                }
            } else {
                return {
                    status: false,
                    message: "Something went wrong. Please try again later"
                }
            }
        })
        .catch(function (error) {
            return handleError(error)
        })
}


const updatePassword = async (data) => {
    if (!isBrowser) return false

    return api.put('/accounts/update_password/', data)
        .then(function (response) {


            if (response.status === 200) {
                return {
                    status: true
                }
            } else {
                return {
                    status: false
                }
            }
        })
        .catch(function (error) {
            return handleError(error)
        })
}

const signOut = () => {
    TokenService.removeUser();
}

const AuthService = {
    register,
    activate,
    signIn,
    getCurrentUser,
    forgotPassword,
    resetPassword,
    updateUserDetails,
    updatePassword,
    signOut
};
export default AuthService;
