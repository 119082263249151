import api from './api'

// Helpers
import { handleError } from '../common/utils/helpers'

const isBrowser = typeof window !== `undefined`

// PRODUCTS

export const getUserCollectionSlugs = async () => {
    if (!isBrowser) return false

    return await api.get(`/shop/get_client_slug_wishlist/`)
        .then(function (response) {
            if (response.status === 200) {
                return {
                    status: true,
                    data: response.data.results
                }
            }
        })
        .catch(function (error) {
            return handleError(error)
        })
}

export const getUserCollectionProducts = async () => {
    if (!isBrowser) return false

    return await api.get(`/shop/get_client_wishlist/`)
        .then(function (response) {
            if (response.status === 200) {
                return {
                    status: true,
                    data: response.data
                }
            }
        })
        .catch(function (error) {
            return handleError(error)
        })
}

export const toggleCollectProduct = async (slug) => {
    if (!isBrowser) return false

    return await api.put(`/shop/toggle_product_wishlist/${slug}/`)
        .then(function (response) {
            if (response.status === 200) {
                return {
                    status: true,
                }
            }
        })
        .catch(function (error) {
            return handleError(error)
        })
}

// MOOD BOARDS

export const createMoodBoard = async () => {
    if (!isBrowser) return false

    return await api.post(`/services/create_moodboard/`)
        .then(function (response) {
            if (response.status === 200) {
                return {
                    status: true,
                    data: response.data
                }
            }
        })
        .catch(function (error) {
            return handleError(error)
        })
}

export const getUserMoodBoards = async () => {
    if (!isBrowser) return false

    return await api.get(`/services/get_moodboards_list/`)
        .then(function (response) {
            if (response.status === 200) {
                return {
                    status: true,
                    data: response.data
                }
            }
        })
        .catch(function (error) {
            return handleError(error)
        })
}

// PROFILE & PROJECTS

export const getDesignerProfile = async () => {
    if (!isBrowser) return false

    return await api.get(`/accounts/designer_profile/`)
        .then(function (response) {
            if (response.status === 200) {
                return {
                    status: true,
                    data: response.data
                }
            }
        })
        .catch(function (error) {
            return handleError(error)
        })
}

export const createDesignerProfile = async (values) => {
    if (!isBrowser) return false

    return await api.post(`/accounts/designer_profile/`, values)
        .then(function (response) {
            if (response.status === 201) {
                return {
                    status: true,
                    data: response.data
                }
            }
        })
        .catch(function (error) {
            return handleError(error)
        })
}

export const updateDesignerProfile = async (values) => {
    if (!isBrowser) return false

    return await api.put(`/accounts/designer_profile/`, values)
        .then(function (response) {
            if (response.status === 200) {
                return {
                    status: true,
                    data: response.data
                }
            }
        })
        .catch(function (error) {
            return handleError(error)
        })
}

export const getDesignerProjects = async () => {
    if (!isBrowser) return false

    return await api.get(`/services/client_projects/`)
        .then(function (response) {
            if (response.status === 200) {
                return {
                    status: true,
                    data: response.data.results
                }
            }
        })
        .catch(function (error) {
            return handleError(error)
        })
}

export const addProject = async (values) => {
    if (!isBrowser) return false

    return await api.post(`/services/client_projects/`, values)
        .then(function (response) {
            if (response.status === 201) {
                return {
                    status: true,
                }
            }
        })
        .catch(function (error) {
            return handleError(error)
        })
}

export const updateProject = async (projectPk, values) => {
    if (!isBrowser) return false

    return await api.put(`/services/client_projects/${projectPk}/`, values)
        .then(function (response) {
            if (response.status === 200) {
                return {
                    status: true,
                }
            }
        })
        .catch(function (error) {
            return handleError(error)
        })
}

export const deleteProject = async (projectPk) => {
    if (!isBrowser) return false

    return await api.delete(`/services/client_projects/${projectPk}/`)
        .then(function (response) {
            if (response.status === 204) {
                return {
                    status: true
                }
            }
        })
        .catch(function (error) {
            return handleError(error)
        })
}

const DashboardService = {
    getUserCollectionSlugs,
    getUserCollectionProducts,
    toggleCollectProduct,
    createMoodBoard,
    getUserMoodBoards,
    getDesignerProfile,
    createDesignerProfile,
    updateDesignerProfile,
    getDesignerProjects,
    addProject,
    updateProject,
    deleteProject
}

export default DashboardService